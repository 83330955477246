import React from 'react'
import './skill.css';

const Skill = ({ imgUrl, title, desc}) => {
  return (
    <div className="skills__container-skill">
        <div className="skills__container-skill-icon">
            {imgUrl}
        </div>
        <div className="skills__container-skill-content">
            <div>
                <h3>{title}</h3>
                <p>{desc}</p>
            </div>    
        </div>
    </div>
  )
}

export default Skill