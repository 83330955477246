import React from 'react'

import { About, Navbar, Projects, Skills } from './components';
import { Header, Footer, Contact, Miniprojects } from './containers';
import './App.css';

const App = () => {
  return (
    <div className="wrapper">
        <Navbar />
        <Header />
        <Skills />
        <About />
        <Projects />
        <Miniprojects />
        <Contact />
        <Footer />
    </div>
  )
}

export default App