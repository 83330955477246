import React from 'react';
import './footer.css';
import { RiLinkedinBoxFill, RiGithubFill } from 'react-icons/ri';

const Footer = () => {
  return (
    <div className="footer section__margin">
      <div className="footer__links">
        <a href='https://www.linkedin.com/in/ryanphoen/' target="_blank" rel='noreferrer'>
          <RiLinkedinBoxFill color="#000" size={30} className="footer__links-button"/>
        </a>
        <a href='https://github.com/rphoen' target="_blank" rel='noreferrer'>
          <RiGithubFill color="#000" size={30} className="footer__links-button"/>
        </a>
      </div>
      <div className="footer__text">
        <p>Copyright 2023 &copy; Ryan Phoen</p>
      </div>
    </div>
  )
}

export default Footer