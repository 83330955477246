import React, {useState} from 'react';
import './navbar.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

const Menu = () => (
  <>
    <p><a href ="#skills">Skills</a></p>
    <p><a href ="#about">About</a></p>
    <p><a href ="#projects">Projects</a></p>
    <p><a href ="#contact">Contact</a></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false)

  return (
    <div className="navbar"> 
      <div className="navbar__links">
        <div className="navbar__links-name">
          <h1>Ryan Phoen</h1>
        </div>
        <div className="navbar__links-container">
          <Menu />
        </div>
      </div>
      <div className="navbar__menu">
        {toggleMenu 
          ? <RiCloseLine color="#000" size={27} onClick={() => setToggleMenu(false)}/>
          : <RiMenu3Line color="#000" size={27} onClick={() => setToggleMenu(true)}/>
        }
        {toggleMenu && (
          <div className="navbar__menu-container slide-in-right">
            <div className="navbar__menu-container_links">
                <Menu />
            </div>
          </div>
        )}
      </div>

    </div>
  )
}

export default Navbar