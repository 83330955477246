import React from 'react'
import './miniproject.css'
import {RiArrowRightSLine } from 'react-icons/ri';

const Miniproject = ({ title, link }) => {
  return (
    <div className="miniprojects__container-miniproject">
        <a href={link}>       
        <div className="miniprojects__container-miniproject-title">
            <h3>{title}</h3>
        </div>
        <div className="miniprojects__container-miniproject-arrow">
          <RiArrowRightSLine className="arrow"/>
        </div>

        </a>
    </div>
  )
}

export default Miniproject