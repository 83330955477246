import {React, useState } from 'react';
import './skills.css';
import Skill from '../skill/Skill';
import { RiCursorFill, RiTeamFill, RiLightbulbFill, RiChat3Fill, RiSearchLine,RiTimeFill, RiFlashlightFill, RiServerFill, RiBarChartFill, RiCodeSSlashFill, RiCloudy2Fill, RiGitBranchFill } from "react-icons/ri";

const Skills = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabToggle = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="skills" id="skills">
      <div className="skills__header">
        <button id="tab1" className={activeTab === 'tab1' ? 'active-tab' : ''} onClick={() => handleTabToggle('tab1')}>Coding skills</button>
        <button id="tab2" className={activeTab === 'tab2' ? 'active-tab' : ''} onClick={() => handleTabToggle('tab2')}>Soft skills</button>
      </div>
      {activeTab === 'tab1' ? (
        <div className="skills__container">
          <Skill imgUrl={<RiCursorFill size={30}/>} title="Frontend Development" desc="HTML5, CSS, Javascript, React, Bootstrap" />
          <Skill imgUrl={<RiCodeSSlashFill size={30}/>} title="Backend Development" desc="Python, Java, C/C++, Golang" />
          <Skill imgUrl={<RiBarChartFill size={30}/>} title="Data Science" desc="Scikit-Learn, Pandas, OpenCV, NumPy, Tensorflow" />
          <Skill imgUrl={<RiCloudy2Fill size={30}/>} title="Web Applications" desc="Creating dynamic and intuitive web experiences" />
          <Skill imgUrl={<RiServerFill size={30}/>} title="Database" desc="Experience with SQL and NoSQL databases" />
          <Skill imgUrl={<RiGitBranchFill size={30}/>} title="CI / CD" desc="Experience working in professional teams with git" />
        </div>
      ) :
      (
        <div className="skills__container">
          <Skill imgUrl={<RiChat3Fill size={30}/>} title="Communication" desc="Bridge technical concepts and client requirements, ensuring seamless collaboration throughout the development process." />
          <Skill imgUrl={<RiLightbulbFill size={30}/>} title="Problem-Solving" desc="Deliver innovative solutions that optimize code performance and enhance application functionality." />
          <Skill imgUrl={<RiSearchLine size={30}/>} title="Attention to Detail" desc="Meticulously reviewing code and conducting rigorous testing, I deliver reliable, bug-free applications that exceed expectations." />
          <Skill imgUrl={<RiTimeFill size={30}/>} title="Time Management" desc="Timely project deliveries without compromising quality." />
          <Skill imgUrl={<RiFlashlightFill size={30}/>} title="Adaptability" desc="Stay updated with the latest technologies and methodologies to meet evolving client needs." />
          <Skill imgUrl={<RiTeamFill size={30}/>} title="Teamwork" desc="Contributing to team success through knowledge sharing and open communication." />
        </div> 
      )}
    </div>
  )
}

export default Skills