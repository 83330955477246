import React from 'react';
import './projects.css';
import { RiGithubFill, RiExternalLinkLine } from "react-icons/ri";
import gpt from '../../assets/gpt.png';
import position from '../../assets/position.gif';
import movieverse from '../../assets/movieverse.png'
import { Fade } from "react-awesome-reveal";

const Projects = () => {
  return (
    <div className="projects section__padding" id="projects">
      <div className="projects__header">
        <h1>Projects</h1>
      </div>
      <div className="projects__container">
        <Fade triggerOnce>
        <div className='projects__container-project'>
          <div className='projects__container-project-image'>
            <a href="https://example-website-gpt.netlify.app" target='_blank' rel='noreferrer'><img src={gpt} alt="gpt"/></a>
          </div>
          <div className="projects__container-project-text">
            <h3>AI Model Website</h3>
            <p>A website that displays the landing screen of an example company using GPT and AI as its product.</p>
            <div className="projects__container-project-skill">
              <p>React</p>
              <p>CSS</p>
            </div>
            <div className='projects__container-project-link'>
              <a href="https://github.com/rphoen/webite_gpt3" target='_blank' rel='noreferrer'>Code <RiGithubFill /></a>
              <a href="https://example-website-gpt.netlify.app" target='_blank' rel='noreferrer'>Live Demo <RiExternalLinkLine /></a>
            </div>
          </div>
        </div>
        </Fade>
        <Fade triggerOnce>
        <div className='projects__container-project project_reverse'>
          <div className="projects__container-project-text">
            <h3>DLW Hackathon 2022 Finalist</h3>
            <p>DLW Hackathon is the biggest annual student-run hackathon in NTU. Here, a group 
                  of friends and I built a computer vision model that can identify if a hawker table is taken
                  based on imagery.</p>
            <div className="projects__container-project-skill">
              <p>OpenCV</p>
              <p>Javascript</p>
              <p>SCSS</p>
            </div>
            <div className='projects__container-project-link'>
              <a href="https://github.com/rphoen/HawkerCV" target='_blank' rel="noreferrer">Code <RiGithubFill /></a>
            </div>
          </div>
          <div className='projects__container-project-image'>
            <a href="https://github.com/rphoen/HawkerCV" target='_blank' rel="noreferrer"><img src={position} alt="position"/></a>
          </div>
        </div>
        </Fade>
        <Fade triggerOnce>
        <div className='projects__container-project'>
          <div className='projects__container-project-image'>
            <a href="https://movieverse-24.netlify.app/" target='_blank' rel="noreferrer"><img src={movieverse} alt="position"/></a>
          </div>
          <div className="projects__container-project-text">
            <h3>Movieverse</h3>
            <p>Movieverse is a movie app that allows users to search for information about various movies utilising the OMDB API.</p>
            <div className="projects__container-project-skill">
              <p>React</p>
              <p>CSS</p>
            </div>
            <div className='projects__container-project-link'>
              <a href="https://github.com/rphoen/movieverse" target='_blank' rel="noreferrer">Code <RiGithubFill /></a>
              <a href="https://movieverse-24.netlify.app/" target='_blank' rel='noreferrer'>Live Demo <RiExternalLinkLine /></a>
            </div>
          </div>
        </div>
        </Fade>
      </div>
    </div>
  )
}

export default Projects