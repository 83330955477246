
import './contact.css';
import React, { useState } from 'react';
// import {useForm } from '@formspree/react';
import envelope from '../../assets/envelope.svg';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://formspree.io/f/mlekzwrl', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Response from API:', data);
        
        // Clear the form after successful submission
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });

  };

  // const [state, handleSubmit] = useForm("mlekzwrl");
  // if (state.succeeded) {
  //     return <p>Thanks for joining!</p>;
  // }

  return (
    <div className="contact section__padding" id='contact'>
      <div className="contact__image">
        <img src={envelope} alt="envelope"/>
      </div>
      <div className="contact__form">
      <div className="contact__header">
        <h2>Get in touch with me</h2>
      </div>
      <div className="contact__container">
        <form onSubmit={handleSubmit} className="contact__container-form">
          <div className="contact__container-name">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
          </div>
          <div className="contact__container-email">
            <label htmlFor="email">Email*</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
          </div>
          <div className="contact__container-message">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="3" value={formData.message} onChange={handleChange} />
          </div>
          <button type="submit" >Submit</button>
        </form>
      </div>
      </div>
      

    </div>
  )
}

export default Contact