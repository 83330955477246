import React from 'react';
import './header.css';
import coding from '../../assets/coding.svg';
import { RiLinkedinBoxFill, RiGithubFill } from 'react-icons/ri';

const Header = () => {
  return (
    <div className='header section__padding'>
      <div className="header__content">
        <h1 className='tracking-in-expand'>
          Hi, I'm Ryan
        </h1>
        <p className='text-focus-in'> BEng Computer Science </p>
        <p className='text-focus-in red'> Nanyang Technological University </p>
        <div className="header__socials">
          <a href='https://www.linkedin.com/in/ryanphoen/' target="_blank" rel='noreferrer' className='text-focus-in'> 
            <RiLinkedinBoxFill color="#000" size={33} className='header__socials-btn'/>
          </a>
          <a href='https://github.com/rphoen' target="_blank" rel='noreferrer' className='text-focus-in'>
            <RiGithubFill color="#000" size={33} className='header__socials-btn'/>
          </a>
        </div>
      </div>
      <div className="header__image">
        <img src={coding} alt="portrait" />
      </div>
    </div>
    
  )
}

export default Header